<template>
  <v-dialog v-model="toogleShowModal" max-width="400" persistent>
    <v-card>
      <new-bills v-if="showNewModal" @reloadData="getBills()" :reload-page="false"/>
      <app-loading v-if="loading"/>
      <v-card v-else>
        <v-card-title>{{ $vuetify.lang.t('$vuetify.value_of', [$vuetify.lang.t('$vuetify.menu.account_bill')]) }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" v-if="!bill_id">
              <v-autocomplete
                v-model="newValue.bill_id"
                :items="bills"
                :label="$vuetify.lang.t('$vuetify.menu.account_bill')"
                item-text="name"
                return-object
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="$store.dispatch('bill/toogleNewModal',true)">
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>{{
                        $vuetify.lang.t("$vuetify.titles.newAction")
                      }}</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" v-if="!shop_id">
              <v-select
                v-model="newValue.shop_id"
                :items="shops"
                :label="$vuetify.lang.t('$vuetify.menu.shop')"
                item-text="name"
                item-value="id"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field-money
                v-model="newValue.cant"
                :label="$vuetify.lang.t('$vuetify.tax.value')"
                :properties="{
                suffix: getCurrency
              }"
                :options="{
                length: 15,
                precision: 2,
                empty: 0.00,
              }"
              />
            </v-col>
            <v-col cols="12" md="6">
              <app-datetime-picker
                v-model="newValue.date_bill"
                :clearText="$vuetify.lang.t('$vuetify.actions.cancel')"
                :okText="$vuetify.lang.t('$vuetify.actions.accept')"
                :label="$vuetify.lang.t('$vuetify.date')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="toogleShowModal(false)">
            <v-icon>mdi-close</v-icon>
            {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
          </v-btn>
          <v-btn
            :disabled="!validForm"
            color="primary"
            @click="saveBill">
            <v-icon>mdi-content-save</v-icon>
            {{ $vuetify.lang.t('$vuetify.actions.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewBills from './NewBills'

export default {
  name: 'BillValue',
  components: { NewBills },
  props: {
    bill_id: {
      type: String,
      default: null
    },
    shop_id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      newValue: {
        cant: 1.00,
        date_bill: new Date(),
        bill_id: null,
        shop_id: null
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('bill', ['bills', 'showNewModal']),
    ...mapState('shop', ['shops']),
    validForm () {
      if (!this.bill_id) {
        return !!this.newValue.bill_id
      }
      return true
    },
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    }
  },
  async created () {
    this.loading = true
    if (!this.bill_id) {
      await this.getBills()
    }
    if (!this.shop_id) {
      await this.getShops()
    }
    this.loading = false
  },
  methods: {
    ...mapActions('bill', ['toogleShowModal', 'saveBillValue', 'getBills']),
    ...mapActions('shop', ['getShops']),
    async saveBill () {
      this.loading = true
      if (this.bill_id) { this.newValue.bill_id = this.bill_id }
      this.newValue.date_bill = this.$moment(this.newValue.date_bill).format('YYYY-MM-DD H:m:s')
      if (this.bill_id) { await this.saveBillValue(this.newValue) } else { this.$emit('updateBills', this.newValue) }
      this.loading = false
      this.$emit('reloadData')
      this.toogleShowModal(false)
    }
  }
}
</script>

<style scoped>

</style>
