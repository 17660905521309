var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"400","persistent":""},model:{value:(_vm.toogleShowModal),callback:function ($$v) {_vm.toogleShowModal=$$v},expression:"toogleShowModal"}},[_c('v-card',[(_vm.showNewModal)?_c('new-bills',{attrs:{"reload-page":false},on:{"reloadData":function($event){return _vm.getBills()}}}):_vm._e(),(_vm.loading)?_c('app-loading'):_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.value_of', [_vm.$vuetify.lang.t('$vuetify.menu.account_bill')]))+" ")]),_c('v-card-text',[_c('v-row',[(!_vm.bill_id)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.bills,"label":_vm.$vuetify.lang.t('$vuetify.menu.account_bill'),"item-text":"name","return-object":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$store.dispatch('bill/toogleNewModal',true)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}],null,false,3693460145)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.titles.newAction")))])])]},proxy:true}],null,false,1335131802),model:{value:(_vm.newValue.bill_id),callback:function ($$v) {_vm.$set(_vm.newValue, "bill_id", $$v)},expression:"newValue.bill_id"}})],1):_vm._e(),(!_vm.shop_id)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.shops,"label":_vm.$vuetify.lang.t('$vuetify.menu.shop'),"item-text":"name","item-value":"id"},model:{value:(_vm.newValue.shop_id),callback:function ($$v) {_vm.$set(_vm.newValue, "shop_id", $$v)},expression:"newValue.shop_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.tax.value'),"properties":{
              suffix: _vm.getCurrency
            },"options":{
              length: 15,
              precision: 2,
              empty: 0.00,
            }},model:{value:(_vm.newValue.cant),callback:function ($$v) {_vm.$set(_vm.newValue, "cant", $$v)},expression:"newValue.cant"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('app-datetime-picker',{attrs:{"clearText":_vm.$vuetify.lang.t('$vuetify.actions.cancel'),"okText":_vm.$vuetify.lang.t('$vuetify.actions.accept'),"label":_vm.$vuetify.lang.t('$vuetify.date')},model:{value:(_vm.newValue.date_bill),callback:function ($$v) {_vm.$set(_vm.newValue, "date_bill", $$v)},expression:"newValue.date_bill"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.toogleShowModal(false)}}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.cancel'))+" ")],1),_c('v-btn',{attrs:{"disabled":!_vm.validForm,"color":"primary"},on:{"click":_vm.saveBill}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.save'))+" ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }