var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showShowModal)?_c('bill-value',{on:{"updateBills":_vm.updateBills}}):_vm._e(),_c('v-app-bar',{attrs:{"flat":"","dense":"","color":"rgba(0, 0, 0, 0)"}},[_c('h4',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.variants.indirect_costs')))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openShowModal()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.new')))],1)],1),_c('p',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.messages.indirect_cost_description')))]),_c('p',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.messages.indirect_cost_description2')))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sale.bills},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteButtonClicked(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.actions.delete')))])])]}},{key:"item.cant",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.cant},on:{"update:returnValue":function($event){return _vm.$set(props.item, "cant", $event)},"update:return-value":function($event){return _vm.$set(props.item, "cant", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field-money',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.tax.value'),"properties":{
              suffix: _vm.getCurrency
            },"options":{
              length: 15,
              precision: 2,
              empty: 0.00,
            }},model:{value:(props.item.cant),callback:function ($$v) {_vm.$set(props.item, "cant", $$v)},expression:"props.item.cant"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.cant)+" ")])]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }