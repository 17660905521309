<template>
  <v-card>
    <app-data-table
      :headers="getTableColumns"
      :items="buy.sale_article_shops"
      :sort-by="['name']"
      :sort-desc="[false, true]"
      :view-edit-button="false"
      :view-new-button="false"
      :view-show-filter="false"
      :options="vBindOption"
      csv-filename="ProductBuys"
      multi-sort
      @delete-row="deleteItem($event)">
      <template
        v-slot:[`item.article_shops`]="{ item }"
        style="max-width: 50px"
      >
        <v-chip
          pill
          style="padding-left: 0px; margin: 1px"
        >
          <v-avatar
            style="margin-left: 0px"
            :color="(item.article_shops.article.color && item.article_shops.article.media.length === 0 )? item.article_shops.article.color : ''"
          >
            <v-img
              v-if="item.article_shops.article.media.length > 0"
              :src="item.article_shops.article.media[0].original_url"
            />
          </v-avatar>
          <b style="margin-left: 5px">{{ item.article_shops.article.nameComplete }}</b>
        </v-chip>
      </template>
      <template
        v-slot:[`item.cant`]="{ item }"
      >
        <div>
          {{ parseFloat(item.cant).toFixed(2) }}
          {{ item.article_shops.article.um ? item.article_shops.article.um.name : '' }}
        </div>
      </template>
      <template
        v-slot:[`item.article_shops.stock`]="{ item }"
      >
        {{ parseFloat(item.article_shops.inventory).toFixed(2) }}
        {{ item.article_shops.article.um ? item.article_shops.article.um.name : '' }}
      </template>
      <template v-slot:[`item.totalCost`]="{ item }">
        {{ `${parseFloat(item.totalCost).toFixed(2)}` + ' ' + getCurrency }}
      </template>
      <template
        v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
      >
        <v-btn
          color="primary"
          fab
          x-small
          dark
          @click="expand(!isExpanded)"
        >
          <v-icon v-if="isExpanded">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 10 10 10 10"
        >
          <v-row>
            <v-col md="4" v-if="!showNewVariantCost"  cols="12">
              <template v-for="(vc, i) in item.sale_variant_cost">
                <p :key="i">
                  {{ $vuetify.lang.t('$vuetify.articles.ref') }} : {{ vc.ref }} <br>
                </p>
                <p :key="item.sale_variant_cost.length + i" v-if="parseFloat(vc.cost).toFixed(2) !== '0.00'">
                  {{ $vuetify.lang.t('$vuetify.variants.cost') }} : {{ vc.cost }} {{ getCurrency }}
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="showModalVariantCost(item, vc)"
                      >
                        <v-icon color="grey lighten-1">
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.actions.edit') }}</span>
                  </v-tooltip>
                </p>

                <v-edit-dialog v-else :key="item.sale_variant_cost.length + i"
                               :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                               :return-value.sync="vc.cost"
                               :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                               large
                               persistent
                               @save="updateCostVariant(vc, item)"
                >
                  <div style="margin-bottom: 15">
                    {{ $vuetify.lang.t('$vuetify.variants.cost') }}: {{ parseFloat(vc.cost).toFixed(2) }} {{getCurrency}}
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 title">
                      {{ $vuetify.lang.t('$vuetify.actions.edit') }}
                    </div>
                    <v-text-field-money
                      v-model="vc.cost"
                      :label="$vuetify.lang.t('$vuetify.variants.cant')"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.0
                      }"
                    />
                  </template>
                </v-edit-dialog>
                <v-edit-dialog :key="2*(item.sale_variant_cost.length + i)"
                               :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                               :return-value.sync="vc.cant"
                               :save-text="$vuetify.lang.t('$vuetify.actions.save')"
                               large
                               persistent
                               @save="$emit('updateArticle', item)"
                >
                  <div style="margin-bottom: 15">
                    {{ $vuetify.lang.t('$vuetify.variants.cant') }}: {{ parseFloat(vc.cant).toFixed(2) }}
                    {{ item.article_shops.article.um ? item.article_shops.article.um.name : '' }}
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 title">
                      {{ $vuetify.lang.t('$vuetify.actions.edit') }}
                    </div>
                    <v-text-field-money
                      v-model="vc.cant"
                      :label="$vuetify.lang.t('$vuetify.variants.cant')"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.0
                      }"
                    />
                  </template>
                </v-edit-dialog>
                <v-divider :key="3*(item.sale_variant_cost.length + i)" style="margin-bottom: 15px"/>
              </template>
            </v-col>
            <v-col  v-if="!showNewVariantCost" md="4" cols="12">
              <v-autocomplete
                v-model="item.sale_article_taxes"
                chips
                multiple
                :items="taxes"
                :label="$vuetify.lang.t('$vuetify.access.access.manager_tax')"
                item-text="name_complete"
                return-object
                @change="$emit('updateArticle', item)"
              />
            </v-col>
            <v-col v-if="!showNewVariantCost" md="4" cols="12">
              <v-autocomplete
                v-model="item.discounts"
                chips
                multiple
                :items="discounts"
                :label="$vuetify.lang.t('$vuetify.access.access.manager_discount')"
                item-text="name_complete"
                return-object
                @change="$emit('updateArticle', item)"/>
            </v-col>
            <v-col md="12" cols="12" v-if="showNewVariantCost" >
              <new-variant-cost @cancelNewVariant="showNewVariantCost=false" :showAll="false" @refreshVariantCost="refreshVariantCost"/>
            </v-col>
          </v-row>
        </td>
      </template>
    </app-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NewVariantCost from '../../../article/commerce/manager-article/NewVariantCost'

export default {
  name: 'CartArticle',
  components: { NewVariantCost },
  props: {
    buy: {
      type: Object,
      default: function () {
        return {}
      }
    },
    taxes: {
      type: Array,
      default: function () {
        return []
      }
    },
    discounts: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      articleShop: null,
      variantCostEdited: null,
      showNewVariantCost: false,
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: true
      }
    }
  },
  computed: {
    ...mapGetters('variantCost', ['getVariantCostAdded']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          with: '15%',
          value: 'article_shops',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.articles.inventory'),
          value: 'article_shops.inventory',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.cant'),
          value: 'cant',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.total_cost'),
          value: 'totalCost',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    },
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    }
  },
  methods: {
    ...mapActions('variantCost', ['updateOnlyCost', 'createVariantCost']),
    async updateCostVariant (action, variantCost, item) {
      variantCost.id = variantCost.variant_cost_id
      if (action === 'cost') { await this.updateOnlyCost(variantCost) }
      this.$emit('updateArticle', item)
    },
    async refreshVariantCost (variantCost) {
      variantCost.article_shop_id = this.articleShop.article_shop_id
      await this.createVariantCost(variantCost).then(() => {
        const saleVariantCost = this.buy.sale_article_shops.filter(artS => artS === this.articleShop)[0].sale_variant_cost.filter(vc => vc !== this.variantCostEdited)
        this.variantCostEdited.ref = this.getVariantCostAdded.ref
        this.variantCostEdited.variant_cost_id = this.getVariantCostAdded.id
        this.variantCostEdited.cost = this.getVariantCostAdded.cost
      })
      this.showNewVariantCost = false
      this.$emit('updateArticle', this.articleShop)
    },
    showModalVariantCost (item, vc) {
      this.articleShop = item
      this.variantCostEdited = vc
      this.showNewVariantCost = true
    },
    deleteItem (item) {
      this.buy.sale_article_shops = this.buy.sale_article_shops.filter(artS => artS !== item)
    }
  }
}
</script>

<style scoped>

</style>
