<template>
  <v-container>
    <new-supplier v-if="$store.state.supplier.showNewModal" />
    <new-tax v-if="$store.state.tax.showNewModal" />
    <new-payment v-if="$store.state.payment.showNewModal" />
    <new-discount v-if="$store.state.discount.showNewModal" />
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="sale.supplier"
          :items="suppliers"
          :label="$vuetify.lang.t('$vuetify.menu.supplier')"
          :loading="isSupplierTableLoading"
          clearable
          @click:clear="sale.provider_id = null"
          item-text="firstName"
          return-object
        >
          <template v-slot:append-outer v-if="access_permit.supplier.actions.create">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    $store.dispatch(
                      'supplier/toogleNewModal',
                      true
                    )
                  "
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>{{
                  $vuetify.lang.t('$vuetify.titles.newAction')
                }}</span>
            </v-tooltip>
          </template>
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
            >
              <v-avatar left>
                <v-img
                  :src="
                    data.item.avatar ||
                      '/assets/avatar/avatar-undefined.jpg'
                  "
                />
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <v-img
                  :src="
                    data.item.avatar ||
                      '/assets/avatar/avatar-undefined.jpg'
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="data.item.email">
                  {{ `${data.item.email}` }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="sale.no_facture"
          :label="$vuetify.lang.t('$vuetify.tax.noFacture')"
          :rules="formRule.required"
          required
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-select
          v-model="sale.taxes"
          :items="taxes"
          :label="$vuetify.lang.t('$vuetify.tax.nameGeneral')"
          :rules="formRule.country"
          chips
          clearable
          deletable-chips
          item-text="name"
          multiple
          required
          return-object
          @change="$emit('updateData')"
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    $store.dispatch(
                      'tax/toogleNewModal',
                      true
                    )
                  "
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>{{
                  $vuetify.lang.t('$vuetify.titles.newAction')
                }}</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="4"
      >
        <v-select
          v-model="sale.discounts"
          :items="discounts"
          :label="$vuetify.lang.t('$vuetify.sale.discountGeneral')"
          :rules="formRule.country"
          chips
          clearable
          deletable-chips
          item-text="name"
          multiple
          required
          return-object
          @change="$emit('updateData')"
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    $store.dispatch(
                      'discount/toogleNewModal',
                      true
                    )
                  "
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>{{
                  $vuetify.lang.t('$vuetify.titles.newAction')
                }}</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <list-pay
          :currency="user.company.currency"
          :edit="edit"
          :sale="sale"
          :sub-total="parseFloat(subTotal).toFixed(2)"
          :total-discount="parseFloat(totalDiscount).toFixed(2)"
          :total-price="parseFloat(totalPrice).toFixed(2)"
          :total-tax="parseFloat(totalTax).toFixed(2)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewTax from '../../../tax/commerce/NewTax'
import NewPayment from '../../../payment/commerce/NewPayment'
import NewDiscount from '../../../discount/commerce/NewDiscount'
import ListPay from '../../../pay/ListPay'
import NewSupplier from '../../../supplier/commerce/NewSupplier'

export default {
  name: 'ExtraData',
  components: {
    NewSupplier,
    ListPay,
    NewPayment,
    NewTax,
    NewDiscount
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    sale: {
      type: Object,
      default: function () {
        return {}
      }
    },
    taxes: {
      type: Array,
      default: function () {
        return []
      }
    },
    totalPrice: {
      type: String,
      default: '0.00'
    },
    totalTax: {
      type: String,
      default: '0.00'
    },
    totalDiscount: {
      type: String,
      default: '0.00'
    },
    subTotal: {
      type: String,
      default: '0.00'
    }
  },
  data () {
    return {
      formRule: this.$rules,
      localDiscounts: []
    }
  },
  computed: {
    ...mapState('supplier', ['suppliers', 'isSupplierTableLoading']),
    ...mapState('payment', ['payments', 'isPaymentLoading']),
    ...mapState('sale', ['newSale', 'editSale']),
    ...mapState('discount', ['discounts']),
    ...mapGetters('auth', ['user', 'access_permit']),
    getTotalCost () {
      return this.totalCost
    }
  },
  async mounted () {
    await this.getSuppliers()
    if (this.suppliers.length > 0 && this.sale.provider_id) {
      this.sale.supplier = this.suppliers.filter(sup => sup.id === this.sale.provider_id)[0]
    }
    await this.getTaxes()
    await this.getPayments()
    await this.getDiscounts()
  },
  methods: {
    ...mapActions('supplier', ['getSuppliers']),
    ...mapActions('tax', ['getTaxes']),
    ...mapActions('payment', ['getPayments']),
    ...mapActions('discount', ['getDiscounts'])
  }
}
</script>

<style scoped></style>
