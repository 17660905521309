<template>

  <no-subcription v-if="!getSubscription"/>
  <div class="page-add-buys" v-else>
    <app-loading v-if="loadingData"/>
    <v-container v-else>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ managerBuy ?
              $vuetify.lang.t('$vuetify.titles.edit', [$vuetify.lang.t('$vuetify.menu.buy')]) : $vuetify.lang.t('$vuetify.menu.order_add')
            }}
            <v-tooltip bottom max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
                    <span>{{$vuetify.lang.t('$vuetify.messages.order_definition')}}</span>
            </v-tooltip>
          </span>

        </v-card-title>
        <v-card-text>
          <v-expansion-panels v-model="panel" multiple style="margin: 0">
            <v-expansion-panel style="margin: 0">
              <v-expansion-panel-header>
                <div>
                  <v-icon>mdi-database-edit</v-icon>
                  <span style="text-transform: uppercase;font-weight: bold">
                      {{ $vuetify.lang.t('$vuetify.panel.basic') }}
                    </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-form
                  ref="form"
                  v-model="formValid"
                  lazy-validation
                  style="padding: 0"
                >

                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-select
                        v-model="buy.shop"
                        :items="buyData.shops"
                        :label="$vuetify.lang.t('$vuetify.menu.shop')"
                        :loading="isTableLoading"
                        :rules="formRule.country"
                        chips
                        :disabled="managerBuy"
                        item-text="name"
                        required
                        return-object
                        @select="changeShop"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-y
                        transition="fab-transition"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            clearable
                            style="margin-top: 15px;margin-left: 15px;"
                            :label="$vuetify.lang.t('$vuetify.menu.category')"
                            v-bind="attrs"
                            v-on="on"
                            :value="getCategoryName"
                            readonly
                            @click:clear="clearCategory"
                          >
                          </v-text-field>
                        </template>
                        <v-card max-height="250">
                          <v-sheet class="pa-4 primary lighten-2">
                            <v-text-field
                              v-model="search"
                              :label="$vuetify.lang.t('$vuetify.actions.search')"
                              dark
                              flat
                              ref="search"
                              :autofocus="true"
                              solo-inverted
                              hide-details
                              clearable
                              clear-icon="mdi-close-circle-outline"
                            ></v-text-field>
                          </v-sheet>
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-treeview
                                  activatable
                                  :search="search"
                                  :filter="filter"
                                  :items="getCategoriesLanguege"
                                  item-text="category"
                                >
                                  <template v-slot:label="{ item }">
                                    <v-list-item two-line @click="selectCategory(item)">
                                      <v-list-item-icon v-if="item.id !== ''">
                                        <v-spacer/>
                                        <v-divider/>
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                              style="margin-top: 10px"
                                              class="mr-2"
                                              small
                                              color="primary"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              mdi-tag
                                            </v-icon>
                                          </template>
                                          <span>{{ $vuetify.lang.t('$vuetify.component.select_one') }}</span>
                                        </v-tooltip>
                                      </v-list-item-icon>
                                      <v-list-item-title>
                                                                              <span
                                                                                v-if="item.id"
                                                                                class="subtitle-2 font-weight-light"
                                                                              >
                                                                                {{
                                                                                  !item.company_id ? UpperCaseFirstLetter(item.category) : item.category
                                                                                }}
                                                                              </span>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </template>
                                </v-treeview>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" v-if="buy.shop">
                      <app-loading v-if="loadingArticle"/>
                      <div v-else>
                        <v-toolbar flat>
                          <v-toolbar-title>
                            <v-tooltip bottom v-if="!showListArticles">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  dark
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="showListArticles = true"
                                >
                                  mdi-eye
                                </v-icon>
                              </template>
                              <span>{{ $vuetify.lang.t('$vuetify.actions.show') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="showListArticles">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  dark
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="showListArticles = false"
                                >
                                  mdi-eye-off
                                </v-icon>
                              </template>
                              <span>{{ $vuetify.lang.t('$vuetify.actions.hidden') }}</span>
                            </v-tooltip>
                            {{ $vuetify.lang.t('$vuetify.titles.list', [$vuetify.lang.t('$vuetify.menu.articles')]) }}
                          </v-toolbar-title>
                        </v-toolbar>
                        <v-row
                          dense
                          v-if="articles_shops.length > 0 && showListArticles"
                        >
                          <v-col
                            cols="12"
                            md="6"
                            sm="8"
                            class="pl-6 pt-6"
                          >
                            <small>{{ $vuetify.lang.t('$vuetify.online.showing') }} {{ page * 9 + 1 }}-
                              {{ articlesFilter.length > 9 * (page + 1) ? 9 * (page + 1) : articlesFilter.length }}
                              {{ $vuetify.lang.t('$vuetify.online.of') }} {{
                                articlesFilter.length
                              }}</small>
                          </v-col>
                          <v-col md="6" col="12">
                            <v-text-field
                              v-model="txtFilter"
                              :label="$vuetify.lang.t('$vuetify.actions.search')"
                              prepend-inner-icon="mdi-magnify"
                              class="ml-4"
                            />
                          </v-col>
                        </v-row>
                        <v-row v-if="showListArticles">
                          <v-col cols="12">
                            <v-item-group active-class="primary">
                              <v-row>
                                <v-col
                                  v-for="item in articlesFilter.slice(page*12, 12*(page + 1))"
                                  :key="item.id"
                                  cols="12"
                                  md="4"
                                >
                                  <v-item>
                                    <article-buy
                                      color="primary"
                                      :article-shops="item"
                                      :currency="getCurrency"
                                      @addToSale="selectArticle"/>
                                  </v-item>
                                </v-col>
                              </v-row>
                            </v-item-group>
                          </v-col>
                        </v-row>
                        <v-divider/>
                        <div
                          v-if="localArticles.length > 12"
                          class="text-center mt-12"
                        >
                          <v-pagination
                            v-model="page"
                            :length="Math.round(localArticles.length/12 -1)"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      v-if="buy.sale_article_shops.length > 0"
                    >
                      <v-toolbar
                        flat
                      >
                        <v-toolbar-title>
                          <v-tooltip bottom v-if="!showCart">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                small
                                dark
                                v-bind="attrs"
                                v-on="on"
                                @click="showCart=true"
                              >
                                mdi-eye
                              </v-icon>
                            </template>
                            <span>{{ $vuetify.lang.t('$vuetify.actions.show') }}</span>
                          </v-tooltip>
                          <v-tooltip bottom v-if="showCart">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                dark
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="showCart=false"
                              >
                                mdi-eye-off
                              </v-icon>
                            </template>
                            <span>{{ $vuetify.lang.t('$vuetify.actions.hidden') }}</span>
                          </v-tooltip>
                          {{
                            $vuetify.lang.t('$vuetify.sale.cart') + ' ' + parseFloat(buy.subTotalCost).toFixed(2) + ' ' + getCurrency
                          }}
                        </v-toolbar-title>
                        <v-divider
                          class="mx-4"
                          inset
                          vertical
                        ></v-divider>
                        <v-spacer/>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="error"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="clearArticles"
                            >
                              mdi-cart-off
                            </v-icon>
                          </template>
                          <span>{{ $vuetify.lang.t('$vuetify.sale.clear_cart') }}</span>
                        </v-tooltip>
                      </v-toolbar>
                      <cart-article
                        v-if="showCart"
                        :buy="buy"
                        :taxes="buyData.taxes ? buyData.taxes.length > taxes.length ? buyData.taxes : taxes : taxes"
                        :discounts="buyData.discounts ? buyData.discounts : []"
                        @updateArticle="articleCalcTotalCost"
                        @updateSaleData="updateSaleData"
                        @clearArticles="clearArticles"/>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="buy.sale_article_shops.length > 0">
              <v-expansion-panel-header>
                <div>
                  <v-icon>mdi-database-plus</v-icon>
                  <span style="text-transform: uppercase;font-weight: bold"> {{
                      $vuetify.lang.t('$vuetify.pay.extra_data')
                    }} </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <extra-data
                  :edit="managerBuy"
                  :sale="buy"
                  :taxes="localTaxes"
                  :sub-total="parseFloat(buy.subTotalCost).toFixed(2)"
                  :total-price="parseFloat(buy.totalCost).toFixed(2)"
                  :total-discount="parseFloat(buy.totalDiscountCost).toFixed(2)"
                  :total-tax="parseFloat(buy.totalTaxCost).toFixed(2)"
                  @updateData="calcTotalBuy"
                />
                <bills-sale-buy :sale="buy"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <action-buttons :to_route="'no_hidden'" :buy="buy"/>
      </v-card>
    </v-container>
  </div>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'
import ArticleBuy from './manger-buy/ArticleBuy'
import localCategories from '../../../data/categories'
import ExtraData from './manger-buy/ExtraData'
import BillsSaleBuy from '../../accounting-bills/BillsSaleBuy'
import CartArticle from './manger-buy/CartArticle'
import ActionButtons from './manger-buy/ActionButtons'

export default {
  name: 'ManagerBuy',
  components: { ActionButtons, CartArticle, BillsSaleBuy, ExtraData, ArticleBuy },
  props: {
    managerBuy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapState('articleShop', ['articles_shops']),
    ...mapState('buy', ['buyData', 'isTableLoading', 'isActionInProgress', 'editBuy']),
    ...mapGetters('auth', ['user']),
    ...mapState('tax', ['taxes', 'isTaxLoading']),
    ...mapState('auth', ['company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getCategoriesLanguege () {
      const localCat = localCategories[this.$vuetify.lang.current]()
      let newLocal = []
      if (this.allActived.length > 0) {
        newLocal = this.updateActiveCat(localCat, newLocal)
        this.allActived.forEach((v) => {
          const cat = this.allCategories.filter(a => parseInt(a.id) === parseInt(v))[0]
          if (cat.company_id) {
            cat.children = []
            if (cat.parent_id) {
              this._find(newLocal, 'id', cat.parent_id, cat)
            } else {
              newLocal.push(cat)
            }
          }
        })
      }
      return newLocal
    },
    getCategoryName () {
      return this.category.category ? this.UpperCaseFirstLetter(this.category.category) : null
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    }
  },
  watch: {
    buyData: function () {
      this.allActived = this.buyData.categories.activated
      this.allCategories = this.buyData.categories.categories
      this.localTaxes = this.buyData.taxes
      this.updateLocalTaxes()
    },
    taxes: function () {
      this.localTaxes = this.taxes
      this.updateLocalTaxes()
    },
    txtFilter: function () {
      this.articlesFilter = this.txtFilter !== ''
        ? this.localArticles.filter(
          art => art.article.name.toLowerCase().includes(this.txtFilter.toLowerCase()) ||
            (art.article.barCode !== null ? art.article.barCode.toLowerCase().includes(this.txtFilter.toLowerCase()) : false)
        ) : this.localArticles
    }
  },
  data () {
    return {
      subTotalCost: 0.00,
      totalTaxCost: 0.00,
      totalDiscountCost: 0.00,
      totalModifierCost: 0.00,
      totalCost: 0.00,
      loadingArticle: false,
      existArticles: true,
      page: 0,
      localTaxes: [],
      localDiscounts: [],
      txtFilter: '',
      allActived: [],
      allCategories: [],
      formValid: false,
      loadingData: false,
      category: {},
      panel: [0],
      formRule: this.$rules,
      localArticles: [],
      search: '',
      articlesFilter: [],
      buy: {
        no_facture: '',
        online: false,
        box: null,
        state: 'preform',
        pays: [],
        taxes: [],
        discounts: [],
        modifiers: [],
        bills: [],
        payments: [],
        sale_article_shops: [],
        shop: null,
        supplier: null
      },
      showListArticles: true,
      showCart: false,
      menu: false
    }
  },
  async created () {
    if (this.managerBuy) {
      await this.getEditBuy(atob(this.$route.params.hash))
      this.buy = this.editBuy
      this.showCart = true
      this.showListArticles = true
    }
    await this.getNewBuyData()
    if (!this.managerBuy) {
      this.buy.shop = this.buyData.shops[0]
    }
    await this.updateDataArticle()
  },
  methods: {
    ...mapActions('buy', ['getNewBuyData', 'createBuy', 'updateBuy', 'getEditBuy']),
    ...mapActions('articleShop', ['findAllToBuy']),
    changeShop () {
      this.updateDataArticle()
    },
    _find (collection, key, value) {
      for (const o of collection) {
        for (const [k, v] of Object.entries(o)) {
          if (k === key && v === value.toString()) {
            return o
          }
          if (Array.isArray(v)) {
            const _o = this._find(v, key, value)
            if (_o) {
              return _o
            }
          }
        }
      }
    },
    async updateDataArticle () {
      this.localArticles = []
      if (this.buy.shop) {
        this.loadingArticle = true
        await this.findAllToBuy({ shop: this.buy.shop.id })
        this.loadingArticle = false
        this.localArticles = this.articles_shops.filter(artS => artS.shop_id === this.buy.shop.id)
        this.existArticles = this.articles_shops.length > 0
      }
      this.articlesFilter = this.localArticles
      this.articlesFilter.map((v) => {
        const exist = this.buy.sale_article_shops.filter(sat => sat.article_shop_id === v.id)
        if (exist.length > 0) {
          v.price = exist[0].price
          v.onlinePrice = exist[0].onlinePrice
          v.cost = exist[0].cost
          v.totalTax = exist[0].totalTax
          v.totalPrice = exist[0].totalPrice
        }
      })
    },
    selectArticle (item, variantCost) {
      if (item) {
        let artS = {}
        if (this.buy.sale_article_shops.filter(art => art.article_shops.article.id === item.article.id).length === 0) {
          artS = {
            article_shops: item,
            sale_variant_cost: [{
              cost: variantCost.cost,
              variant_cost_id: variantCost.id,
              ref: variantCost.ref,
              cant: 1
            }],
            article_shop_id: item.id,
            cant: 1,
            subTotalCost: item.article.cost,
            totalCost: parseFloat(parseFloat(variantCost.cost) + parseFloat(item.totalTax)).toFixed(2),
            sale_article_taxes: item.article.taxes.length === 0 ? [] : this.getSaleArticleTaxes(item.article.taxes),
            modifiers: [],
            discounts: []
          }
          this.buy.sale_article_shops.push(artS)
        } else {
          this.buy.sale_article_shops.forEach((artShops) => {
            if (artShops.article_shops.article_id === item.article_id) {
              artS = artShops
              if (artS.sale_variant_cost.filter((vc) => vc.variant_cost_id === variantCost.id).length > 0) {
                artS.sale_variant_cost.map((vc) => {
                  if (vc.variant_cost_id === variantCost.id) {
                    vc.cant += 1
                  }
                })
              } else {
                artS.sale_variant_cost.push({
                  cost: variantCost.cost,
                  variant_cost_id: variantCost.id,
                  ref: variantCost.ref,
                  cant: 1
                })
              }
            }
          })
        }
        this.articleCalcTotalCost(artS)
        this.showCart = true
      }
    },
    updateLocalTaxes () {
      if (this.buy.sale_article_shops.length > 0) {
        this.buy.sale_article_shops.forEach((artS) => {
          artS.sale_article_taxes.forEach((v) => {
            this.localTaxes = this.localTaxes.filter(t => t.id !== v.tax_id)
            this.buy.taxes = this.buy.taxes.filter(t => t.id !== v.tax_id)
          })
        })
      }
    },
    updateSaleData (sale) {
      this.buy = sale
      this.calcTotalBuy()
    },
    getSaleArticleTaxes (taxes) {
      const lTax = []
      taxes.forEach(tx => {
        lTax.push({
          tax_id: tx.id,
          name: tx.name,
          name_complete: tx.name_complete,
          values_id: tx.values_id,
          type: tx.type,
          cant: tx.cant,
          percent: tx.percent
        })
      })
      return lTax
    },
    clearArticles () {
      this.buy.sale_article_shops = []
      this.totalArticles = 0
    },
    articleCalcTotalCost (saleArticleShops) {
      saleArticleShops.subTotalCost = 0
      saleArticleShops.cant = 0
      saleArticleShops.sale_variant_cost.forEach((vc) => {
        saleArticleShops.subTotalCost += vc.cost * parseFloat(vc.cant)
        saleArticleShops.cant += parseFloat(vc.cant)
      })
      saleArticleShops.totalModifierCost = 0.00
      saleArticleShops.totalDiscountCost = 0
      saleArticleShops.discounts.forEach((v) => {
        saleArticleShops.totalDiscountCost += v.percent ? (saleArticleShops.subTotalCost + saleArticleShops.totalModifierCost) * v.cant / 100 : v.cant
      })
      saleArticleShops.totalTaxCost = 0
      saleArticleShops.sale_article_taxes.forEach((tax) => {
        saleArticleShops.totalTaxCost += tax.percent ? (saleArticleShops.subTotalCost + saleArticleShops.totalModifierCost - saleArticleShops.totalDiscountCost) * tax.cant / 100 : tax.cant
      })
      saleArticleShops.totalCost = saleArticleShops.subTotalCost + saleArticleShops.totalModifierCost - saleArticleShops.totalDiscountCost + saleArticleShops.totalTaxCost
      this.calcTotalBuy()
    },
    calcTotalBuy () {
      this.buy.totalTaxCost = 0
      this.buy.totalDiscountCost = 0
      this.buy.totalCost = 0
      this.buy.subTotalCost = 0
      this.buy.sale_article_shops.forEach((v) => {
        this.buy.subTotalCost = parseFloat(v.totalCost) + this.buy.subTotalCost
      })
      this.buy.discounts.forEach((v) => {
        this.buy.totalDiscountCost += v.percent === 'true' ? this.buy.subTotalCost * v.cant / 100 : v.cant
      })
      this.buy.taxes.forEach((v) => {
        this.buy.totalTaxCost += v.percent === 'true' ? (this.buy.subTotalCost - this.buy.totalDiscountCost) * v.cant / 100 : v.cant
      })
      this.buy.totalCost = (this.buy.subTotalCost + parseFloat(this.buy.totalTaxCost) - parseFloat(this.buy.totalDiscountCost)).toFixed(2)
      this.buy.totalCost = parseFloat(this.buy.totalCost).toFixed(2)
      this.updateLocalTaxes()
    },
    updateActiveCat (collection, newLocal) {
      for (const o of collection) {
        const ele = {
          category: o.category,
          id: o.id,
          children: []
        }
        if (o.children && Array.isArray(o.children) && o.children.length > 0) {
          const _o = this.updateActiveCat(o.children, ele.children)
          if (!Array.isArray(_o)) {
            ele.children.push(_o)
          }
        }
        if (this.allActived.filter(act => parseInt(act) === parseInt(ele.id)).length > 0 || ele.children.length > 0) {
          if (!Array.isArray(ele)) {
            newLocal.push(ele)
          }
        }
      }
      return newLocal
    },
    clearCategory () {
      this.articlesFilter = this.localArticles
    },
    selectCategory (item) {
      this.category = item
      this.articlesFilter = this.localArticles.filter(art => art.article.category_id === parseInt(item.id))
      this.menu = false
      this.page = 0
    },
    UpperCaseFirstLetter (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }
}
</script>

<style scoped>

</style>
