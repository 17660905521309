<template>
  <v-card width="300px">
    <v-card-text>
      <v-row v-if="showFacture">
        <v-spacer/>
        <v-col
          v-if="sale.no_facture"
          class="py-0"
          cols="12"
          md="12"
        >
          <b>{{ $vuetify.lang.t('$vuetify.tax.noFacture') }}</b>:<br>
          {{ sale.no_facture }}
        </v-col>
      </v-row>
      <v-row>
        <v-col style="padding-bottom: 0px !important;"
          cols="12"
          md="6"
        >
          <b>{{ $vuetify.lang.t('$vuetify.pay.sub_total') }}</b>
        </v-col>
        <v-col style="padding-bottom: 0px !important;"
          cols="12"
          md="6"
        >
          {{ parseFloat(subTotal).toFixed(2) }} {{ `${getCurrency}` }}
        </v-col>
      </v-row>
      <template v-if="sale.discounts.length > 0" style="margin-bottom: 0px !important;">
        <v-row style="margin-bottom: 0px !important;"
          v-for="disc in sale.discounts"
          :key="disc.local_name"
        >
          <v-col
            cols="12"
            md="6"
          >
            <b
              style="color: red"
            >{{ $vuetify.lang.t('$vuetify.menu.discount') }}({{
                disc.name
              }})</b>
          </v-col>
          <v-col
            v-if="disc.percent === 'true'"
            cols="12"
            md="5"
          >
            <i
              style="color: red"
            >{{
                `${parseFloat((disc.cant * subTotal) / 100).toFixed(2)}` + ' ' + getCurrency
              }}
              ({{ disc.cant }}%)</i>
          </v-col>
          <v-col
            v-else
            cols="12"
            md="5"
          >
            <i style="color: red">{{
                `${parseFloat(disc.cant).toFixed(2) + ' ' + getCurrency}`
              }}</i>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row>
          <v-col md="6" cols="12" style="padding-bottom: 0px !important;"/>
          <v-col md="6" cols="12" style="padding-bottom: 0px !important;">
            <p class="align-content-end" style="margin-bottom: 0px !important;">{{ parseFloat(subTotal - totalDiscount).toFixed(2) + ' ' + getCurrency }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-if="sale.taxes.length > 0">
        <v-row
          v-for="tax in sale.taxes"
          :key="tax.name"
        >
          <v-col
            cols="12"
            md="6"
          >
            <b
              style="color: darkblue"
            >{{ $vuetify.lang.t('$vuetify.tax.name') }}({{
                tax.name
              }})</b>
          </v-col>
          <v-col
            v-if="tax.percent === 'true'"
            cols="12"
            md="6"
          >
            <i
              style="color: darkblue"
            >{{ `${parseFloat((tax.cant * (subTotal - totalDiscount)) / 100).toFixed(2)}` + ' ' + getCurrency }}
              ({{ parseFloat(tax.cant).toFixed(2) }}%)</i>
          </v-col>
          <v-col
            v-else
            cols="12"
            md="6"
          >
            <i style="color: darkblue">{{
                `${parseFloat(tax.cant).toFixed(2) + ' ' + getCurrency}`
              }}</i>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row>
        <v-col
            cols="12"
            md="6"
          >
            <b style="text-transform: uppercase">{{
                $vuetify.lang.t('$vuetify.report.netPrice')
              }}</b>
          </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <template>
            {{ `${parseFloat(parseFloat(totalPrice)).toFixed(2)}` + ' ' + getCurrency }}
          </template>
        </v-col>

        </v-row>
      </template>
      <template v-if="sale.refunds ? sale.refunds.length > 0: false">
        <v-divider v-if="sale.refunds ? sale.refunds.length > 0: false"/>
        <template
          v-for="refunds in sale.refunds">
          <v-row  v-for="refund in refunds.refunds"
                  :key="refund.id"
          >
            <v-col
              cols="12"
              md="6"
            >
              <i
                style="color: red"
              >({{ $vuetify.lang.t('$vuetify.menu.refund') }}) {{$vuetify.lang.t('$vuetify.payment.' + refund.bank_payment.method)}}</i>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <i style="color: red">-{{
                  `${parseFloat(refund.money).toFixed(2) + ' ' + getCurrency}`
                }}</i>
            </v-col>
          </v-row>
        </template>
      </template>
      <v-row v-if="sale.delivery">
        <v-col
          cols="12"
          md="6"
        >
          <b style="text-transform: uppercase">{{
              $vuetify.lang.t('$vuetify.menu.delivery')
            }}</b>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <template v-if="parseFloat(totalDelivery).toFixed(2) !== '0.00'">
            {{ `${parseFloat(totalDelivery).toFixed(2) + ' ' + getCurrency}` }}
          </template>
          <template v-else>
            {{ '0.00 ' + getCurrency }}
          </template>
        </v-col>
      </v-row>
      <v-row v-if="sale.perquisite">
        <v-col
          cols="12"
          md="6"
        >
          <b style="text-transform: uppercase">{{
              $vuetify.lang.t('$vuetify.menu.perquisite')
            }}</b>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <template v-if="parseFloat(totalPerquisite).toFixed(2) !== '0.00'">
            {{ `${parseFloat(totalPerquisite).toFixed(2) + ' ' + getCurrency}` }}
          </template>
          <template v-else>
            {{ '0.00 ' + getCurrency }}
          </template>
        </v-col>
      </v-row>
      <v-divider v-if="sale.delivery || sale.perquisite"/>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <b style="text-transform: uppercase">{{
              $vuetify.lang.t('$vuetify.report.final_price')
            }}</b>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <template>
            {{ `${parseFloat(parseFloat(totalPrice) + parseFloat(totalDelivery) + parseFloat(totalPerquisite)).toFixed(2)}` + ' ' + getCurrency }}
          </template>
        </v-col>
      </v-row>
      <v-divider v-if="showIndirect && sale.bills.length > 0"/>
      <h1 style="color: #e86464" v-if="showIndirect && sale.bills.length > 0">{{$vuetify.lang.t('$vuetify.variants.indirect_costs').toUpperCase()}}</h1>
      <v-row style="color: #e86464" v-if="showIndirect && sale.bills.length > 0">
        <template v-for="(bill, i) in sale.bills">
          <v-col cols="12" md="6" :key="i">
            <b>{{ bill.name }}</b>
          </v-col>
          <v-col cols="12" md="6" :key="sale.bills.length + i">
            {{ parseFloat(bill.cant).toFixed(2) }} {{ getCurrency }}
          </v-col>
        </template>
      </v-row>
      <v-divider v-if="showIndirect && sale.bills.length > 0"/>
      <v-row style="color: #e86464" v-if="showIndirect && sale.bills.length > 0">
        <v-col md="6" cols="12">
          {{ $vuetify.lang.t('$vuetify.variants.indirect_cost') }}({{ $vuetify.lang.t('$vuetify.pay.total') }})
        </v-col>
        <v-col md="6" cols="12">
          {{ parseFloat(sale.totalBills).toFixed(2) }} {{ getCurrency }}
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <b style="text-transform: uppercase">{{
              $vuetify.lang.t('$vuetify.pay.total')
            }}</b>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <b style="text-transform: uppercase">{{
              parseFloat(parseFloat(totalPrice) + parseFloat(sale.totalBills)).toFixed(2)
            }} {{ getCurrency }}</b>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Facture',
  props: {
    online: {
      type: Boolean,
      default: false
    },
    onlineConfig: {
      type: Object,
      default: function () {
        return {}
      }
    },
    edit: {
      type: Boolean,
      default: false
    },
    showIndirect: {
      type: Boolean,
      default: false
    },
    update: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      default: ''
    },
    sale: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showFacture: {
      type: Boolean,
      default: true
    },
    totalPrice: {
      type: String,
      Number,
      default: '0.00'
    },
    totalDelivery: {
      type: String,
      Number,
      default: '0.00'
    },
    totalPerquisite: {
      type: String,
      Number,
      default: '0.00'
    },
    totalTax: {
      type: String,
      Number,
      default: '0.00'
    },
    totalDiscount: {
      type: String,
      Number,
      default: '0.00'
    },
    subTotal: {
      type: String,
      Number,
      default: '0.00'
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'userPin']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    }
  },
  mounted () {
    console.log(this.sale)
    console.log(this.subTotal)
  }
}
</script>

<style scoped>
.profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

* {
  font-size: 12px;
  font-family: "Times New Roman";
}

td,
th,
tr,
table {
  border-top: 1px solid black;
  border-collapse: collapse;
}

td.producto,
th.producto {
  width: 80px;
  max-width: 80px;
}

td.cantidad,
th.cantidad {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
}

td.precio,
th.precio {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
}

.centrado {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 155px;
  max-width: 155px;
}

img {
  max-width: inherit;
  width: inherit;
}
</style>
