<template>
  <v-card-actions v-if="buy">
    <v-spacer/>
    <v-btn
      color="error"
      class="mb-2"
      :disabled="isActionInProgress"
      @click="handleClose"
    >
      <v-icon>mdi-close</v-icon>
      {{ !smallScreen ? $vuetify.lang.t('$vuetify.actions.cancel') : '' }}
    </v-btn>
    <template v-if="buy.state === 'preform' && buy.payments.length === 0">
      <v-btn
        class="mb-2"
        color="success"
        :disabled="!formValid || isActionInProgress || buy.sale_article_shops.length === 0"
        :loading="isActionInProgress"
        @click="createPreform()"
      >
        <v-icon>mdi-calendar-clock</v-icon>
        {{ !smallScreen ? $vuetify.lang.t('$vuetify.sale.state.preform') : '' }}
      </v-btn>
    </template>
    <v-btn
      v-show="buy.state !== 'accepted'"
      class="mb-2"
      color="success"
      :disabled="!formValid || isActionInProgress || buy.sale_article_shops.length === 0 || buy.payments.length === 0"
      :loading="isActionInProgress"
      @click="saleHandler('open')"
    >
      <v-icon>mdi-check</v-icon>
      {{ !smallScreen ? $vuetify.lang.t('$vuetify.sale.state.open') : '' }}
    </v-btn>
    <v-btn
      class="mb-2"
      color="primary"
      :disabled="!formValid || isActionInProgress || buy.sale_article_shops.length === 0 || buy.payments.length === 0"
      :loading="isActionInProgress"
      @click="saleHandler('accepted')"
    >
      <v-icon>mdi-check-all</v-icon>
      {{ !smallScreen ? $vuetify.lang.t('$vuetify.sale.state.accepted') : '' }}
    </v-btn>
  </v-card-actions>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ActionButtons',
  props: {
    formValid: {
      type: Boolean,
      default: true
    },
    buy: {
      type: Object,
      default: function () {
        return {}
      }
    },
    to_route: {
      type: String,
      default: 'hidden'
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapGetters('auth', ['user']),
    ...mapState('buy', ['buyData', 'isTableLoading', 'isActionInProgress', 'editBuy']),
    getDifference () {
      let totalCalcP = 0.00
      this.buy.payments.forEach(v => {
        totalCalcP += parseFloat(v.cant)
      })
      return parseFloat(this.buy.totalCost) - parseFloat(totalCalcP)
    }
  },
  methods: {
    ...mapActions('buy', ['getNewBuyData', 'createBuy', 'updateBuy', 'getEditBuy']),
    createPreform () {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.newF', [
            this.$vuetify.lang.t('$vuetify.menu.buy_product')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_preform'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.accept'
          ),
          confirmButtonColor: 'success'
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.managerBuy('preform')
          }
        })
    },
    saleHandler (state) {
      if (this.buy.payments.filter(py => py.bank_payment.method === 'credit').length > 0 && !this.buy.supplier) {
        this.loading = false
        this.shopMessageError(this.$vuetify.lang.t('$vuetify.messages.warning_no_supplier_credit'), 1)
      } else if (parseFloat(this.getDifference).toFixed(2) !== '0.00' && state === 'accepted') {
        this.loading = false
        this.shopMessageError(this.$vuetify.lang.t(
          '$vuetify.messages.warning_difference_price', [(-parseFloat(this.getDifference).toFixed(2) + ' ' + this.user.company.currency).toString()]
        ))
      } else {
        if (this.buy.sale_article_shops.length > 0) {
          this.managerBuy(state)
        } else {
          this.loading = false
          this.shopMessageError(this.$vuetify.lang.t(
            '$vuetify.messages.warning_cant_article'
          ))
        }
      }
    },
    async managerBuy (state) {
      this.loading = true
      this.buy.state = state
      if ((!this.buy.no_facture || !this.buy.no_facture === '') && state === 'accepted') {
        this.shopMessageError(this.$vuetify.lang.t('$vuetify.messages.warning_no_facture'), 1)
      } else {
        if (!this.buy.id) {
          await this.createBuy(this.buy).then(() => {
            this.goToView(state)
          })
        } else {
          await this.updateBuy(this.buy).then(() => {
            this.goToView(state)
          })
        }
      }
    },
    goToView () {
      if (this.to_route === 'hidden') {
        this.$emit('hiddenDialog')
      } else if (this.buy.state === 'accepted') {
        this.$router.push({ name: 'buy_products' })
      } else if (this.buy.state === 'open') {
        this.$router.push({ name: 'supply_process' })
      } else if (this.buy.state === 'preform') {
        this.$router.push({ name: 'supply_emit' })
      } else { this.handleClose() }
    },
    shopMessageError (message, active) {
      this.$Swal.fire({
        title: this.$vuetify.lang.t('$vuetify.titles.newF', [
          this.$vuetify.lang.t('$vuetify.sale.sale')
        ]),
        text: message,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: this.$vuetify.lang.t(
          '$vuetify.actions.accept'
        ),
        confirmButtonColor: 'red'
      })
        .then(result => {
          if (result.isConfirmed) this.panel = active
        })
    },
    handleClose () {
      this.localArticles = []
      this.buy.sale_article_shops = []
      if (this.to_route === 'hidden') {
        this.$emit('hiddenDialog')
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped>

</style>
