<template>
  <div>
    <bill-value v-if="showShowModal" @updateBills="updateBills"/>
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <h4>{{ $vuetify.lang.t('$vuetify.variants.indirect_costs') }}</h4>
      <v-spacer/>
      <v-btn @click="openShowModal()" color="primary">
        <v-icon>mdi-plus</v-icon>{{ $vuetify.lang.t('$vuetify.actions.new') }}</v-btn>
    </v-app-bar>
    <p>{{ $vuetify.lang.t('$vuetify.messages.indirect_cost_description') }}</p>
    <p>{{ $vuetify.lang.t('$vuetify.messages.indirect_cost_description2') }}</p>
    <v-data-table
      :headers="headers"
      :items="sale.bills"
    >

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="error"
              small
              v-bind="attrs"
              v-on="on"
              @click="deleteButtonClicked(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.actions.delete') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.cant="props">
        <v-edit-dialog
          :return-value.sync="props.item.cant"
        >
          {{ props.item.cant }}
          <template v-slot:input>
            <v-text-field-money
              v-model="props.item.cant"
              :label="$vuetify.lang.t('$vuetify.tax.value')"
              :properties="{
                suffix: getCurrency
              }"
              :options="{
                length: 15,
                precision: 2,
                empty: 0.00,
              }"
            />
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import BillValue from './BillValue'

export default {
  name: 'BillsSaleBuy',
  components: { BillValue },
  props: {
    sale: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      pagination: {}
    }
  },
  computed: {
    ...mapState('bill', ['bills', 'showShowModal']),
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    headers () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.first_name'),
          value: 'name',
          select_filter: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.variants.cant'),
          value: 'cant',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions('bill', ['getBills', 'toogleNewModal', 'openEditModal', 'openShowModal', 'getBillsFilter']),
    deleteButtonClicked (item) {
      this.sale.bills = this.sale.bills.filter(b => b.bill_id !== item.bill_id)
    },
    updateBills (bill) {
      if (this.sale.bills.filter(v => v.bill_id === bill.bill_id.id).length > 0) {
        this.sale.bills.map((v) => {
          if (v.bill_id === bill.bill_id.id) { v.cant = parseFloat(v.cant) + parseFloat(bill.cant) }
        })
      } else {
        this.sale.bills.push({
          bill_id: bill.bill_id.id,
          name: bill.bill_id.name,
          cant: bill.cant
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
