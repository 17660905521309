var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.$store.state.supplier.showNewModal)?_c('new-supplier'):_vm._e(),(_vm.$store.state.tax.showNewModal)?_c('new-tax'):_vm._e(),(_vm.$store.state.payment.showNewModal)?_c('new-payment'):_vm._e(),(_vm.$store.state.discount.showNewModal)?_c('new-discount'):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.suppliers,"label":_vm.$vuetify.lang.t('$vuetify.menu.supplier'),"loading":_vm.isSupplierTableLoading,"clearable":"","item-text":"firstName","return-object":""},on:{"click:clear":function($event){_vm.sale.provider_id = null}},scopedSlots:_vm._u([(_vm.access_permit.supplier.actions.create)?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$store.dispatch(
                    'supplier/toogleNewModal',
                    true
                  )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}],null,false,1111404804)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.titles.newAction')))])])]},proxy:true}:null,{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected},on:{"click":data.select}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":data.item.avatar ||
                    '/assets/avatar/avatar-undefined.jpg'}})],1),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}},{key:"item",fn:function(data){return [[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":data.item.avatar ||
                    '/assets/avatar/avatar-undefined.jpg'}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")]),(data.item.email)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(`${data.item.email}`)+" ")]):_vm._e()],1)]]}}],null,true),model:{value:(_vm.sale.supplier),callback:function ($$v) {_vm.$set(_vm.sale, "supplier", $$v)},expression:"sale.supplier"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.tax.noFacture'),"rules":_vm.formRule.required,"required":""},model:{value:(_vm.sale.no_facture),callback:function ($$v) {_vm.$set(_vm.sale, "no_facture", $$v)},expression:"sale.no_facture"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.taxes,"label":_vm.$vuetify.lang.t('$vuetify.tax.nameGeneral'),"rules":_vm.formRule.country,"chips":"","clearable":"","deletable-chips":"","item-text":"name","multiple":"","required":"","return-object":""},on:{"change":function($event){return _vm.$emit('updateData')}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$store.dispatch(
                    'tax/toogleNewModal',
                    true
                  )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.titles.newAction')))])])]},proxy:true}]),model:{value:(_vm.sale.taxes),callback:function ($$v) {_vm.$set(_vm.sale, "taxes", $$v)},expression:"sale.taxes"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.discounts,"label":_vm.$vuetify.lang.t('$vuetify.sale.discountGeneral'),"rules":_vm.formRule.country,"chips":"","clearable":"","deletable-chips":"","item-text":"name","multiple":"","required":"","return-object":""},on:{"change":function($event){return _vm.$emit('updateData')}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$store.dispatch(
                    'discount/toogleNewModal',
                    true
                  )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.titles.newAction')))])])]},proxy:true}]),model:{value:(_vm.sale.discounts),callback:function ($$v) {_vm.$set(_vm.sale, "discounts", $$v)},expression:"sale.discounts"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('list-pay',{attrs:{"currency":_vm.user.company.currency,"edit":_vm.edit,"sale":_vm.sale,"sub-total":parseFloat(_vm.subTotal).toFixed(2),"total-discount":parseFloat(_vm.totalDiscount).toFixed(2),"total-price":parseFloat(_vm.totalPrice).toFixed(2),"total-tax":parseFloat(_vm.totalTax).toFixed(2)}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }