<template>
  <v-dialog
    v-model="toogleNewModal"
    max-width="600px"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          $vuetify.lang.t("$vuetify.titles.new", [
            $vuetify.lang.t("$vuetify.menu.supplier")
          ])
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="formValid"
          class="my-10"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newSupplier.name"
                :label="$vuetify.lang.t('$vuetify.firstName')"
                :rules="formRule.firstName"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newSupplier.email"
                :label="
                  $vuetify.lang.t('$vuetify.supplier.email')
                "
                autocomplete="off"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newSupplier.contract"
                :counter="16"
                maxlength="16"
                :label="
                  $vuetify.lang.t(
                    '$vuetify.supplier.contract'
                  )
                "
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newSupplier.rnc"
                :label="$vuetify.lang.t('$vuetify.fiscal.rnc')"
                :hint="$vuetify.lang.t('$vuetify.hints.rnc')"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <vue-tel-input-vuetify
                v-model="newSupplier.phone"
                :placeholder="
                  $vuetify.lang.t('$vuetify.phone_holder')
                "
                :label="
                  $vuetify.lang.t('$vuetify.supplier.phone')
                "
                :select-label="
                  $vuetify.lang.t('$vuetify.supplier.country')
                "
                v-bind="bindProps"
                :error-messages="errorPhone"
                :prefix="
                  countrySelect
                    ? `+` + countrySelect.dialCode
                    : ``
                "
                @country-changed="onCountry"
                @keypress="numbers"
                @input="onInput"
              >
                <template #message="{ key, message }">
                  <slot
                    name="label"
                    v-bind="{ key, message }"
                  />
                  {{ message }}
                </template>
              </vue-tel-input-vuetify>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-switch
                v-model="newSupplier.walking"
                :label="$vuetify.lang.t('$vuetify.articles.walking')"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="newSupplier.address"
                :label="
                  $vuetify.lang.t('$vuetify.supplier.address')
                "
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="newSupplier.note"
                :label="
                  $vuetify.lang.t('$vuetify.supplier.note')
                "
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="mb-2"
          :disabled="isActionInProgress"
          @click="toogleNewModal(false)"
        >
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!formValid || isActionInProgress"
          :loading="isActionInProgress"
          class="mb-2"
          color="primary"
          @click="createNewClient"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.actions.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      showModal: false,
      formValid: false,
      hidePinCode1: true,
      hidePinCode2: true,
      errorPhone: null,
      formRule: this.$rules,
      countrySelect: null
    }
  },
  computed: {
    ...mapState('supplier', ['saved', 'newSupplier', 'isActionInProgress']),
    ...mapGetters('auth', ['isManagerIn']),
    bindProps () {
      return {
        mode: 'national',
        clearable: true,
        disabledFetchingCountry: false,
        autocomplete: 'off',
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    }
  },
  created () {
    this.formValid = false
  },
  methods: {
    ...mapActions('supplier', ['createSupplier', 'toogleNewModal']),
    onCountry (event) {
      this.newSupplier.country = event.iso2
      this.countrySelect = event
    },
    numbers (event) {
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    onInput (number, object) {
      const lang = this.$vuetify.lang
      if (object.valid) {
        this.newSupplier.phone = number.replace(' ', '')
        this.errorPhone = null
      } else {
        this.errorPhone = lang.t('$vuetify.rule.bad_phone', [
          lang.t('$vuetify.phone')
        ])
      }
    },
    lettersNumbers (event) {
      const regex = new RegExp('^[a-zA-Z0-9 ]+$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    createNewClient () {
      if (this.$refs.form.validate()) {
        if (this.newSupplier.email) {
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.new', [
                this.$vuetify.lang.t('$vuetify.supplier.name')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_requested_provider'
              ),
              icon: 'info',
              showCancelButton: true,
              cancelButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.no'
              ),
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.yes'
              ),
              confirmButtonColor: 'primary'
            })
            .then(result => {
              this.loading = true
              this.newSupplier.sendEmail = result.value
              this.createSupplier(this.newSupplier)
            })
        } else {
          this.createSupplier(this.newSupplier)
        }
      }
    }
  }
}
</script>

<style scoped></style>
